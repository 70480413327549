import classNames from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import Checkbox from '@rio-cloud/rio-uikit/lib/es/Checkbox';
import ContentLoader from '@rio-cloud/rio-uikit/lib/es/ContentLoader';
import NotFoundState from '@rio-cloud/rio-uikit/lib/es/NotFoundState';
import SortArrows from '@rio-cloud/rio-uikit/lib/es/SortArrows';
import { FormattedMessage, useIntl } from 'react-intl';
import { ExternalAsset } from '../../generated/GeneratedApiTypes';
import InternalErrorState from '../app/InternalErrorState';
import { deselectRow, selectRow, setHeaderCheckboxState } from '../app/utils/TableActionsUtils';
import PaginationControlsComponent from './PaginationControlsComponent';
import ReassignVehiclesSearch from './ReassignVehiclesSearch';
import TableRow from './ReassignVehiclesTableRow';
import { useBasicDialogActions } from './hooks/useBasicDialogActions';
import type { FetchVehicleSortingData } from './hooks/useFetchVehicles';
import { useReassignVehiclesDialog } from './hooks/useReassignVehiclesDialog';
import { DATA_ATTRIBUTE, FetchItems, sortByVehicleColumns, useSearch, vehicleColumns } from './hooks/useSearch';
import ReassignVehiclesDialog from './reassign-vehicles-dialog/ReassignVehiclesDialog';
import VehiclesSelectedDialog from './vehicles-selected-dialog/VehiclesSelectedDialog';

type ColumnKey = keyof typeof sortByVehicleColumns;

const renderTableHead = (column: ColumnKey, sortingData: FetchVehicleSortingData) => (
    <th
        key={column}
        className={classNames('user-select-none', 'sort-column', 'text-capitalize')}
        onClick={sortingData.handleSortChange}
        data-field={column}
        data-sortby={sortByVehicleColumns[column]}
    >
        <span>
            {sortingData.sortBy === sortByVehicleColumns[column] ? (
                <SortArrows direction={sortingData.sortDir} />
            ) : (
                <SortArrows />
            )}
            <span>
                <FormattedMessage id={`workshopAssignment.columnLabels.${column}`} />
            </span>
        </span>
    </th>
);

const getVehiclesIds = (vehicles: ExternalAsset[]): string[] => vehicles.map((vehicle) => vehicle.asset_id);

const ReassignVehiclesTable = () => {
    const [selectedVehicles, setSelectedVehicles] = useState<ExternalAsset[]>([]);
    const [selectAll, setSelectAll] = useState<boolean>(true);
    const fetchItems: FetchItems = 'fetchVehicles';
    const useSearchHook = useSearch(fetchItems);
    const {
        searchResult,
        hasErrors,
        isLoading,
        triggerReloadVehicles,
        vehiclePaginationData: { handlePageChange, pageNumber, totalPages },
        vehicleSortingData,
    } = useSearchHook;

    const {
        reassignVehiclesDialog,
        doReassignment,
        showReassignmentState,
        selectedStep,
        handleDoReassignment,
        openReassignVehiclesDialog,
        closeReassignVehiclesDialog,
        handleShowReassignmentState,
        handlePageNext,
        handleStepChange,
    } = useReassignVehiclesDialog(triggerReloadVehicles);

    const { closeDialog, isDialogVisible, toggleDialog } = useBasicDialogActions();
    const intl = useIntl();

    // @ts-ignore
    let tableRef: HTMLTableElement | null = useRef(null);

    useEffect(() => {
        if (!tableRef || !tableRef.rows) return;

        const tableRows = [...tableRef.rows];
        const selectedRowIds = getVehiclesIds(selectedVehicles);
        selectedRowIds.forEach((rowId) => selectRow(tableRows, rowId, DATA_ATTRIBUTE));
        setHeaderCheckboxState(tableRows, selectedRowIds);
    });

    const isTargetCheckbox = (target: { classList: { value: any } }) => {
        const classListValue = target.classList.value;
        return classListValue === 'checkbox-text' || classListValue === 'checkbox';
    };

    const handleActiveRowChange = (event: any) => {
        event.preventDefault();
        event.stopPropagation();

        if (isTargetCheckbox(event.target)) handleSelection(event);
    };

    const handleSelection = (event: React.MouseEvent<HTMLElement>) => {
        const selectedRowId = event.currentTarget.getAttribute(DATA_ATTRIBUTE);

        if (selectedRowId) {
            const isRemoval = selectedVehicles.some((vehicle) => vehicle.asset_id === selectedRowId);
            if (isRemoval) {
                setSelectedVehicles(
                    selectedVehicles.filter((asset: ExternalAsset) => asset.asset_id !== selectedRowId),
                );
                deselectRows([selectedRowId]);
            } else {
                setSelectedVehicles([
                    ...selectedVehicles,
                    searchResult.filter((asset: ExternalAsset) => asset.asset_id === selectedRowId)[0],
                ]);
            }
        }
    };

    const handleToggleAll = () => {
        if (selectAll) {
            setSelectedVehicles(searchResult);
        } else {
            deselectRows(getVehiclesIds(selectedVehicles));
            setSelectedVehicles([]);
        }
        setSelectAll(!selectAll);
    };

    const removeSelected = () => {
        const allRowIds = searchResult.map((vehicle) => `${vehicle.asset_id}`);

        deselectRows(allRowIds);
        setSelectedVehicles([]);
        setSelectAll(true);
    };

    const deselectRows = (rowIds: string[]): boolean => {
        const tableRows = [...tableRef!!.rows];
        rowIds.forEach((rowId: string) => deselectRow(tableRows, rowId, DATA_ATTRIBUTE));
        return true;
    };

    const handleSuccessAssigning = () => {
        setSelectedVehicles([]);
    };

    const memoizedTableHead = useMemo(
        () => (
            <>
                <colgroup>
                    <col className="table-checkbox" />
                    {vehicleColumns.map((column: React.Key) => (
                        <col key={column} />
                    ))}
                </colgroup>
                <thead>
                    <tr data-testid="reassign-vehicles-table-header">
                        <th className="table-checkbox" style={{ paddingRight: '0px', paddingLeft: '8px' }}>
                            <Checkbox aria-label="toggle-all-checkbox" onClick={handleToggleAll} />
                        </th>
                        {vehicleColumns.map((column: React.Key) =>
                            renderTableHead(column as ColumnKey, vehicleSortingData),
                        )}
                    </tr>
                </thead>
            </>
        ),
        [vehicleSortingData],
    );

    const tableClassNames = classNames(
        'table',
        'table-layout-fixed',
        'table-column-overflow-hidden',
        'table-bordered',
        'table-sticky-in-container',
        'table-head-filled',
    );

    const renderTable = () =>
        searchResult.length ? (
            <div className="table-responsive margin-top-10 overflow-y-scroll reassignAssetsTable">
                <table ref={(node) => (tableRef = node)} className={tableClassNames}>
                    {memoizedTableHead}
                    <tbody data-testid={'reassign-vehicles-table-body'}>
                        {searchResult.map((row: ExternalAsset) => (
                            <TableRow
                                key={`vehicle-selection-${row.asset_name}`}
                                row={row}
                                columns={vehicleColumns}
                                onClick={handleActiveRowChange}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
        ) : (
            <NotFoundState
                headline={intl.formatMessage({ id: 'workshopAssignment.notFoundState.headline' })}
                message={intl.formatMessage({ id: 'workshopAssignment.notFoundState.message' })}
            />
        );

    if (hasErrors) {
        return <InternalErrorState reloadTriggerFunction={triggerReloadVehicles} />;
    }
    if (isLoading) {
        return <ContentLoadingScreen />;
    }

    const reassignButtonClasses = `btn btn-primary margin-bottom-15 ${selectedVehicles.length === 0 ? 'disabled' : ''}`;
    return (
        <div id="ReassignVehiclesTable" data-testid={'reassign-vehicles-table'}>
            <div className="display-flex justify-content-start flex-nowrap margin-bottom-10">
                <ReassignVehiclesSearch useSearchHook={useSearchHook} />
            </div>

            <div className="btn-toolbar">
                <div className="btn-group">
                    <button
                        data-testid={'reassign-button'}
                        className={reassignButtonClasses}
                        onClick={openReassignVehiclesDialog}
                    >
                        <span className="rioglyph rioglyph-to-right" />
                        <span>
                            <FormattedMessage id="workshopAssignment.button.reassignTo" />
                        </span>
                    </button>
                </div>
                {selectedVehicles.length > 0 && (
                    <div className="btn-group">
                        <button
                            type="button"
                            className="btn btn-default margin-left-10"
                            aria-label="selected"
                            onClick={toggleDialog}
                        >
                            <span>
                                {selectedVehicles.length}{' '}
                                <FormattedMessage id="workshopAssignment.vehicleSelection.selected" />
                            </span>
                        </button>
                        <button
                            type="button"
                            className="btn btn-default dropdown-toggle"
                            aria-label="deselect all"
                            onClick={removeSelected}
                        >
                            <span className="rioglyph rioglyph-remove margin-0" />
                        </button>
                    </div>
                )}
            </div>
            {renderTable()}

            <PaginationControlsComponent
                isLoading={isLoading}
                pageNumber={pageNumber}
                totalPages={totalPages}
                onPageNumberChange={handlePageChange}
            />

            <ReassignVehiclesDialog
                isOpenDialog={reassignVehiclesDialog}
                closeDialog={closeReassignVehiclesDialog}
                showReassignmentState={showReassignmentState}
                selectedStep={selectedStep}
                selectedVehicles={selectedVehicles}
                doReassignment={doReassignment}
                handleDoReassignment={handleDoReassignment}
                handleShowReassignmentState={handleShowReassignmentState}
                handlePageNext={handlePageNext}
                handleStepChange={handleStepChange}
                onSuccessAssigning={handleSuccessAssigning}
            />

            <VehiclesSelectedDialog
                onClose={closeDialog}
                showDialog={isDialogVisible}
                selectedVehicles={selectedVehicles}
                onRemove={handleSelection}
            />
        </div>
    );
};

const ContentLoadingScreen = () => (
    <>
        <ContentLoader height={20} width={370} className="padding-left-15 margin-top-15" />
        <ContentLoader height={35} width={150} className="padding-left-15 margin-top-50" />
    </>
);

export default ReassignVehiclesTable;
