import ErrorState from '@rio-cloud/rio-uikit/lib/es/ErrorState';
import { FormattedMessage } from 'react-intl';

export type InternalErrorStateProps = {
    reloadTriggerFunction?: () => void;
    additionalClassName?: string;
    innerClassName?: string;
    fullWidth?: boolean;
};

const InternalErrorState = ({
    reloadTriggerFunction,
    additionalClassName,
    innerClassName,
    fullWidth,
}: InternalErrorStateProps) => (
    <ErrorState
        headline={<FormattedMessage id="workshopAssignment.common.error.emptyState.headline" />}
        message={<FormattedMessage id="workshopAssignment.common.error.emptyState.message" />}
        buttons={[
            {
                text: <FormattedMessage id="workshopAssignment.common.error.emptyState.button" />,
                onClick: reloadTriggerFunction,
            },
        ]}
        // className should keep the original values plus the news values passed
        className={`display-flex justify-content-center max-width-100pct ${additionalClassName}`}
        innerClassName={innerClassName}
        fullWidth={fullWidth}
    />
);

export default InternalErrorState;
