import CustomState from '@rio-cloud/rio-uikit/lib/es/CustomState';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { gaPush } from '../../../configuration/setup/googleAnalytics';

type ReassignmentStateProps = {
    hasErrors: boolean;
    numberOfSuccessfullyReassignedVehicles: number;
};

const ReassignmentState = ({ hasErrors, numberOfSuccessfullyReassignedVehicles }: ReassignmentStateProps) => {
    useEffect(() => {
        gaPush({
            event: 'number_of_reassigned_vehicles',
            parameters: {
                element_name: 'ReassignmentState',
                component_name: 'Button',
                flow_name: 'reassign_vehicles',
                trigger: 'click',
                value: numberOfSuccessfullyReassignedVehicles,
            },
            userProps: {},
        });
    }, [numberOfSuccessfullyReassignedVehicles]);

    if (hasErrors)
        return (
            <CustomState
                headline={
                    <span className="text-bold text-color-darkest">
                        <FormattedMessage
                            id={'workshopAssignment.reassignVehiclesDialog.reassignmentState.error.headline'}
                        />
                    </span>
                }
                message={
                    <FormattedMessage
                        id={'workshopAssignment.reassignVehiclesDialog.reassignmentState.error.content'}
                    />
                }
                icons={[
                    {
                        name: 'rioglyph rioglyph-remove-sign',
                        className: 'text-size-400pct',
                        color: 'text-color-highlight',
                    },
                ]}
                outerClassName="border-none"
            />
        );

    return (
        <CustomState
            headline={
                <span className="text-bold text-color-darkest">
                    <FormattedMessage
                        id={'workshopAssignment.reassignVehiclesDialog.reassignmentState.success.headline'}
                    />
                </span>
            }
            message={
                <FormattedMessage
                    id={'workshopAssignment.reassignVehiclesDialog.reassignmentState.success.content'}
                    values={{ count: numberOfSuccessfullyReassignedVehicles }}
                />
            }
            icons={[
                {
                    name: 'rioglyph rioglyph-ok-sign',
                    className: 'text-size-400pct',
                    color: 'text-color-highlight',
                },
            ]}
            outerClassName="border-none"
        />
    );
};

export default ReassignmentState;
