type PaginationControlsComponentProps = {
    isLoading: boolean;
    pageNumber: number;
    totalPages: number;
    onPageNumberChange: (pageNumber: number) => void;
};

const PaginationControlsComponent = ({
    isLoading,
    pageNumber,
    totalPages,
    onPageNumberChange,
}: PaginationControlsComponentProps) => {
    if (pageNumber < 1 || totalPages < 1) {
        return null;
    }

    return (
        <div className="display-flex flex-wrap margin-top-20 flex-1-0" data-testid={'wa-pagination-controls-component'}>
            <PageNumberComponent
                disabled={isLoading}
                pageNumber={pageNumber}
                totalPages={totalPages}
                onPageNumberChange={onPageNumberChange}
            />
        </div>
    );
};

type PageNumberComponentProps = {
    disabled: boolean;
    pageNumber: number;
    totalPages: number;
    onPageNumberChange: Function;
};

const PageNumberComponent = ({ disabled, pageNumber, totalPages, onPageNumberChange }: PageNumberComponentProps) => {
    const renderPageNumberOption = (pn: number) => {
        let onClickHandler = () => onPageNumberChange(pn);
        let activeClass = '';
        let disabledClass = '';
        if (pn === pageNumber) {
            onClickHandler = () => {};
            activeClass = 'active';
            disabledClass = 'disabled';
        } else if (disabled) {
            onClickHandler = () => {};
            disabledClass = 'disabled';
        }
        return (
            <li key={`page_number_${pn}`} className={`${activeClass} ${disabledClass}`} onClick={onClickHandler}>
                <span>{pn}</span>
            </li>
        );
    };

    const numbersRendered = [];
    if (pageNumber > 1) {
        numbersRendered.push(renderPageNumberOption(1));
    }
    if (pageNumber > 3) {
        numbersRendered.push(<span key="key_first_dots">...</span>);
    }
    if (pageNumber > 2) {
        numbersRendered.push(renderPageNumberOption(pageNumber - 1));
    }
    numbersRendered.push(renderPageNumberOption(pageNumber));
    if (totalPages > pageNumber + 1) {
        numbersRendered.push(renderPageNumberOption(pageNumber + 1));
    }
    if (totalPages > pageNumber + 2) {
        numbersRendered.push(<span key="key_second_dots">...</span>);
    }
    if (totalPages !== pageNumber) {
        numbersRendered.push(renderPageNumberOption(totalPages));
    }
    return (
        <ul className={'pagination pagination-buttons flex-1-0'}>
            <li
                className={`${pageNumber === 1 || disabled ? 'disabled' : ''}`}
                onClick={() => !disabled && pageNumber !== 1 && onPageNumberChange(pageNumber - 1)}
            >
                <span className={'rioglyph rioglyph-chevron-left'} />
            </li>
            {numbersRendered}
            <li
                className={`${pageNumber === totalPages || disabled ? 'disabled' : ''}`}
                onClick={() => !disabled && pageNumber !== totalPages && onPageNumberChange(pageNumber + 1)}
            >
                <span className={'rioglyph rioglyph-chevron-right'} />
            </li>
        </ul>
    );
};

export default PaginationControlsComponent;
