import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TypeFromArray } from '../../utils/types';

export const FEATURE_SLICE_NAME = 'featureToggle';

/**
 * When adding a toggle create a constant and add it to CURRENT_TOGGLES array
 */
const CURRENT_TOGGLES = [] as const;

type KnownToggles = TypeFromArray<typeof CURRENT_TOGGLES>;

type EventContent = {
    featureKey: KnownToggles;
    value: string | undefined;
};

type FeatureToggleState = {
    features: {
        [key in KnownToggles]: boolean;
    };
};

const initialState: FeatureToggleState = {
    features: {},
};

const featureToggleSlice = createSlice({
    initialState,
    name: FEATURE_SLICE_NAME,
    reducers: {
        featureToggleChanged: (state: FeatureToggleState, action: PayloadAction<EventContent>) => {
            const { featureKey, value } = action.payload;
            if (CURRENT_TOGGLES.includes(featureKey)) {
                // @ts-ignore
                state.features[featureKey] = value !== undefined ? /true/i.test(value) : false;
            }
        },
    },
});

export const { featureToggleChanged } = featureToggleSlice.actions;
export default featureToggleSlice.reducer;
