/* eslint-disable max-len */
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import SteppedProgressBar from '@rio-cloud/rio-uikit/lib/es/SteppedProgressBar';
import React, { createContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ExternalAsset, ExternalWorkshop, Reassign } from '../../../generated/GeneratedApiTypes';
import { DATA_ATTRIBUTE } from '../hooks/useSearch';
import useValidationStep from '../hooks/useValidationStep';
import ReassignValidationStep from './ReassignValidationStep';
import ReassignmentState from './ReassignmentState';
import WorkshopSelectionStep from './WorkshopSelectionStep';
import { InputSearchTextFieldName } from './WorkshopSelectionStepSearch';

type ReassignVehiclesDialogProps = {
    isOpenDialog: boolean;
    doReassignment: boolean;
    showReassignmentState: boolean;
    selectedStep: number;
    closeDialog: (hasErrors: boolean) => void;
    selectedVehicles: ExternalAsset[];
    handleDoReassignment: () => void;
    handleShowReassignmentState: () => void;
    handlePageNext: () => void;
    handleStepChange: (selectedKey: any) => void;
    onSuccessAssigning: () => void;
};
type InputSearchTextRefType = {
    workshopInputRef: HTMLInputElement | null;
    setWorkshopInputRef: ((el: HTMLInputElement | null) => void) | null;
    cityInputRef: HTMLInputElement | null;
    setCityInputRef: ((el: HTMLInputElement | null) => void) | null;
    focusOn?: InputSearchTextFieldName;
    setFocusOn: ((fieldName: InputSearchTextFieldName) => void) | null;
};

export const InputSearchTextRef = createContext<InputSearchTextRefType>({
    workshopInputRef: null,
    setWorkshopInputRef: null,
    cityInputRef: null,
    setCityInputRef: null,
    setFocusOn: null,
});

type RequestBody = Reassign.ReassignAssets.RequestBody;

const ReassignVehiclesDialog = ({
    isOpenDialog,
    doReassignment,
    showReassignmentState,
    selectedStep,
    handleDoReassignment,
    handleShowReassignmentState,
    handlePageNext,
    handleStepChange,
    closeDialog,
    selectedVehicles,
    onSuccessAssigning,
}: ReassignVehiclesDialogProps) => {
    const [selectedRowId, setSelectedRowId] = useState<string>('');
    const [reassignButtonVisibility, setReassignButtonVisibility] = useState<boolean>(false);
    const [selectedWorkshop, setSelectedWorkshop] = useState<ExternalWorkshop>({
        city: '',
        workshop_id: '',
        workshop_name: '',
    });

    const [focusOn, setFocusOn] = useState<InputSearchTextFieldName>();
    const [workshopInputRef, setWorkshopInputRef] = useState<HTMLInputElement | null>(null);
    const [cityInputRef, setCityInputRef] = useState<HTMLInputElement | null>(null);

    const intl = useIntl();
    const SELECT_WORKSHOP_STEP = 0;

    const handleSelection = (event: React.MouseEvent<HTMLElement>, currentList: ExternalWorkshop[]) => {
        const rowId = event.currentTarget.getAttribute(DATA_ATTRIBUTE);

        if (rowId) setSelectedRowId(rowId);

        const workshop = currentList.find((elem) => elem.workshop_id === rowId);
        if (workshop) setSelectedWorkshop(workshop);
    };

    const handleReassignVisibility = (dryRunStatus: boolean) => setReassignButtonVisibility(!dryRunStatus);

    const body: RequestBody = {
        workshop_id: selectedWorkshop.workshop_id,
        assets_id: selectedVehicles.map((vehicle) => vehicle.asset_id),
    };
    const { hasErrors, numberOfSuccessfullyReassignedVehicles, isLoading } = useValidationStep(
        body,
        handleReassignVisibility,
        handleShowReassignmentState,
        doReassignment,
        selectedStep,
    );

    const pageContent = [
        <InputSearchTextRef.Provider
            key="firstStep"
            value={{
                cityInputRef,
                focusOn,
                workshopInputRef,
                setCityInputRef,
                setFocusOn,
                setWorkshopInputRef,
            }}
        >
            <WorkshopSelectionStep selectedRowId={selectedRowId} handleSelection={handleSelection} />
        </InputSearchTextRef.Provider>,
        <ReassignValidationStep
            key="secondStep"
            selectedWorkshop={selectedWorkshop}
            selectedVehicles={selectedVehicles}
            handleAfterDryRunAssignment={handleReassignVisibility}
        />,
    ];
    const renderBody = () => (
        <>
            {showReassignmentState ? (
                <ReassignmentState
                    hasErrors={hasErrors}
                    numberOfSuccessfullyReassignedVehicles={numberOfSuccessfullyReassignedVehicles}
                />
            ) : (
                <>
                    <div className="margin-bottom-25 overflow-x-hidden">
                        <SteppedProgressBar
                            variant={SteppedProgressBar.VARIANT_CIRCLE}
                            selectedStepNumber={selectedStep}
                            onSelectedChanged={handleStepChange}
                            mobileBreakpoint={false}
                            labels={[
                                {
                                    icon:
                                        selectedStep === SELECT_WORKSHOP_STEP ? (
                                            '1'
                                        ) : (
                                            <i className="rioglyph rioglyph-ok" />
                                        ),
                                    label: intl.formatMessage({
                                        id: 'workshopAssignment.reassignVehiclesDialog.steps.reassignTo',
                                    }),
                                },
                                {
                                    icon: '2',
                                    label: intl.formatMessage({
                                        id: 'workshopAssignment.reassignVehiclesDialog.steps.resume',
                                    }),
                                },
                            ]}
                        />
                    </div>
                    {pageContent[selectedStep]}
                </>
            )}
        </>
    );

    const renderFooter = () => {
        return selectedStep === 0 ? (
            <button
                data-testid={'next-button'}
                className={`btn btn-primary ${selectedRowId === '' ? 'disabled' : ''}`}
                onClick={handlePageNext}
            >
                <FormattedMessage id="workshopAssignment.reassignVehiclesDialog.reassignTo.footer" />
            </button>
        ) : (
            <button
                data-testid={'reassign-button'}
                className="btn btn-primary"
                disabled={reassignButtonVisibility || isLoading}
                onClick={handleDoReassignment}
            >
                <span>
                    <FormattedMessage id="workshopAssignment.button.reassign" />
                </span>
            </button>
        );
    };

    return (
        <Dialog
            show={isOpenDialog}
            title={<FormattedMessage id="workshopAssignment.reassignVehiclesDialog.reassignTo.title" />}
            body={renderBody()}
            showCloseButton
            bsSize={selectedStep === 0 ? Dialog.SIZE_XL : Dialog.SIZE_MD}
            onHide={() => {
                if (showReassignmentState) {
                    onSuccessAssigning();
                }
                closeDialog(hasErrors);
            }}
            footer={!showReassignmentState && renderFooter()}
            useOverflow
        />
    );
};

export default ReassignVehiclesDialog;
