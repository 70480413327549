import { useState } from 'react';
import { SortBy } from '../../../generated/GeneratedApiTypes';
import useFetchVehicles from './useFetchVehicles';
import useFetchWorkshops from './useFetchWorkshops';

export const DATA_ATTRIBUTE = 'data-key';
export const VEHICLE = 'vehicle';
const ASSET_NAME = SortBy.ASSET_NAME;
export const VIN = SortBy.VIN;
export const CUSTOMER = 'customer';
const ACCOUNT_NAME = SortBy.ACCOUNT_NAME;
const CURRENT_WORKSHOP = 'currentWorkshop';
const WORKSHOP_NAME = SortBy.WORKSHOP_NAME;

export const vehicleColumns = [VEHICLE, VIN, CUSTOMER, CURRENT_WORKSHOP];
export const sortByVehicleColumns = {
    [`${VEHICLE}`]: ASSET_NAME,
    [`${VIN}`]: VIN,
    [`${CUSTOMER}`]: ACCOUNT_NAME,
    [`${CURRENT_WORKSHOP}`]: WORKSHOP_NAME,
};

export const WORKSHOP = 'workshop';
export const CITY = 'city';

export const workshopColumns = [WORKSHOP, CITY];
export const sortByWorkshopColumns = {
    [`${WORKSHOP}`]: WORKSHOP_NAME,
    [`${CITY}`]: CITY,
};

export type FetchItems = 'fetchVehicles' | 'fetchWorkshops';

export const useSearch = (fetchItems: FetchItems) => {
    const {
        filteredAssets,
        hasErrors: hasErrorsVehicles,
        isLoading: isLoadingVehicles,
        triggerReload: triggerReloadVehicles,
        selectedCustomers,
        setSelectedCustomers,
        selectedVehicles,
        setSelectedVehicles,
        paginationData: vehiclePaginationData,
        sortingData: vehicleSortingData,
    } = useFetchVehicles(fetchItems);
    const {
        filteredWorkshops,
        hasErrors: hasErrorsWorkshops,
        isLoading: isLoadingWorkshops,
        triggerReload: triggerReloadWorkshops,
        selectedWorkshops,
        setSelectedWorkshops,
        selectedCities,
        setSelectedCities,
    } = useFetchWorkshops(fetchItems);
    const [searchCustomer, setSearchCustomer] = useState<string>('');
    const [searchVehicle, setSearchVehicle] = useState<string>('');
    const [searchWorkshop, setSearchWorkshop] = useState<string>('');
    const [searchCity, setSearchCity] = useState<string>('');
    const [currentCustomerSuggestions, setCurrentCustomerSuggestions] = useState<any>([]);
    const [currentVehicleSuggestions, setCurrentVehicleSuggestions] = useState<any>([]);
    const [currentWorkshopSuggestions, setCurrentWorkshopSuggestions] = useState<any>([]);
    const [currentCitySuggestions, setCurrentCitySuggestions] = useState<any>([]);

    const removeItemByLabel = (itemToRemove: any, itemList: any, list: string) =>
        itemList.filter((item: any) =>
            list === 'customers'
                ? item.customer !== itemToRemove.customer
                : item.vin !== itemToRemove.vin || item.vehicle !== itemToRemove.vehicle,
        );

    const removeItemByLabelDialog = (itemToRemove: any, itemList: any, list: string) =>
        itemList.filter((item: any) =>
            list === 'workshops' ? item.workshop !== itemToRemove.workshop : item.city !== itemToRemove.city,
        );

    const handleCustomerSuggestionSelected = (_: any, { suggestion }: any) => {
        const selectedCustomer = [...selectedCustomers, suggestion];
        setSelectedCustomers(selectedCustomer);
        setCurrentCustomerSuggestions(removeItemByLabel(suggestion, currentCustomerSuggestions, 'customers'));
        setSearchCustomer('');
        vehiclePaginationData.handlePageChange(1);
    };

    const handleVehicleSuggestionSelected = (_: any, { suggestion }: any) => {
        const selectedVehicle = [...selectedVehicles, suggestion];
        vehiclePaginationData.handlePageChange(1);
        setSelectedVehicles(selectedVehicle);
        setCurrentVehicleSuggestions(removeItemByLabel(suggestion, currentVehicleSuggestions, 'vehicles'));
        setSearchVehicle('');
    };

    const handleWorkshopSuggestionSelected = (_: any, { suggestion }: any) => {
        const selectedWorkshop = [...selectedWorkshops, suggestion];
        setSelectedWorkshops(selectedWorkshop);
        setCurrentWorkshopSuggestions(removeItemByLabelDialog(suggestion, currentWorkshopSuggestions, 'workshops'));
        setSearchWorkshop('');
    };

    const handleCitySuggestionSelected = (_: any, { suggestion }: any) => {
        const selectedCity = [...selectedCities, suggestion];
        setSelectedCities(selectedCity);
        setCurrentCitySuggestions(removeItemByLabelDialog(suggestion, currentCitySuggestions, 'cities'));
        setSearchCity('');
    };

    const handleCustomerSuggestionsChange = ({ value }: any) => setSearchCustomer(value);
    const handleVehicleSuggestionsChange = ({ value }: any) => setSearchVehicle(value);
    const handleWorkshopSuggestionsChange = ({ value }: any) => setSearchWorkshop(value);
    const handleCitySuggestionsChange = ({ value }: any) => setSearchCity(value);

    const handleRemoveSelectedCustomer = (itemToRemove: any) => {
        const newSelectedCustomers = [...selectedCustomers].filter((x) => x.account_id !== itemToRemove.account_id);
        setSelectedCustomers(newSelectedCustomers);
    };

    const handleRemoveSelectedVehicle = (itemToRemove: any) => {
        const newSelectedVehicles = [...selectedVehicles].filter((x) => x.asset_id !== itemToRemove.asset_id);
        setSelectedVehicles(newSelectedVehicles);
    };

    const handleRemoveSelectedWorkshop = (itemToRemove: any) => {
        const newSelectedWorkshops = [...selectedWorkshops].filter((x) => x.workshop_id !== itemToRemove.workshop_id);
        setSelectedWorkshops(newSelectedWorkshops);
    };

    const handleRemoveSelectedCity = (itemToRemove: any) => {
        const newSelectedCities = [...selectedCities].filter((x) => x !== itemToRemove);
        setSelectedCities(newSelectedCities);
    };

    const handleDeleteAllCustomerFilters = () => setSelectedCustomers([]);

    const handleDeleteAllVehicleFilters = () => setSelectedVehicles([]);

    return {
        searchResult: filteredAssets,
        searchResultDialog: filteredWorkshops,
        searchCustomer,
        searchVehicle,
        searchWorkshop,
        searchCity,
        selectedCustomers,
        selectedVehicles,
        selectedWorkshops,
        selectedCities,
        vehiclePaginationData,
        vehicleSortingData,
        currentCustomerSuggestions,
        currentVehicleSuggestions,
        currentWorkshopSuggestions,
        currentCitySuggestions,
        setCurrentCustomerSuggestions,
        setCurrentVehicleSuggestions,
        setCurrentWorkshopSuggestions,
        setCurrentCitySuggestions,
        handleCustomerSuggestionSelected,
        handleCustomerSuggestionsChange,
        handleVehicleSuggestionSelected,
        handleVehicleSuggestionsChange,
        handleWorkshopSuggestionSelected,
        handleWorkshopSuggestionsChange,
        handleCitySuggestionSelected,
        handleCitySuggestionsChange,
        handleRemoveSelectedCustomer,
        handleRemoveSelectedVehicle,
        handleRemoveSelectedWorkshop,
        handleRemoveSelectedCity,
        handleDeleteAllCustomerFilters,
        handleDeleteAllVehicleFilters,
        hasErrors: hasErrorsVehicles || hasErrorsWorkshops,
        isLoading: isLoadingVehicles || isLoadingWorkshops,
        triggerReloadVehicles,
        triggerReloadWorkshops,
    };
};

export type UseSearchHook = ReturnType<typeof useSearch>;
