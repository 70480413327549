import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from '../../configuration/setup/store';

interface AppState {
    sessionExpiredAcknowledged: boolean;
    readinessNotified: boolean;
}

const initialState: AppState = {
    sessionExpiredAcknowledged: false,
    readinessNotified: false,
};

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        hideSessionExpiredDialog: (state) => {
            state.sessionExpiredAcknowledged = true;
        },
        readinessNotified: (state) => {
            state.readinessNotified = true;
        },
    },
});

export const { readinessNotified } = appSlice.actions;

export const getSessionExpiredAcknowledged = (state: RootState) => state.app.sessionExpiredAcknowledged;

export const isReadinessNotifiedSelector = (state: RootState) => state.app.readinessNotified;

export default appSlice.reducer;
