/* eslint-disable max-len */
import ContentLoader from '@rio-cloud/rio-uikit/lib/es/ContentLoader';
import NotFoundState from '@rio-cloud/rio-uikit/lib/es/NotFoundState';
import SortArrows from '@rio-cloud/rio-uikit/lib/es/SortArrows';
import { sortByProperty } from '@rio-cloud/rio-uikit/lib/es/SortUtils';
import classNames from 'classnames';
import { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { ExternalWorkshop, SortBy } from '../../../generated/GeneratedApiTypes';
import InternalErrorState from '../../app/InternalErrorState';
import { selectRow } from '../../app/utils/TableActionsUtils';
import { DATA_ATTRIBUTE, sortByWorkshopColumns, workshopColumns } from '../hooks/useSearch';
import useSearchWorkshops from '../hooks/useSearchWorkshops';
import { useSorting } from '../hooks/useSorting';
import ReassignVehiclesDialogTableRow from './ReassignVehiclesDialogTableRow';
import WorkshopSelectionStepSearch from './WorkshopSelectionStepSearch';

type WorkshopSelectionStepProps = {
    selectedRowId: string;
    handleSelection: (event: React.MouseEvent<HTMLElement>, currentList: ExternalWorkshop[]) => void;
};

const WorkshopSelectionStep = ({ selectedRowId, handleSelection }: WorkshopSelectionStepProps) => {
    type ColumnKey = keyof typeof sortByWorkshopColumns;
    const { sortBy, sortDir, handleSortChange } = useSorting(SortBy.WORKSHOP_NAME);
    const {
        isLoading,
        hasErrors,
        triggerReload,
        workshops,
        setSearchWorkshopNameTextDebounced,
        setSearchCityTextDebounced,
        searchCityText,
        searchWorkshopNameText,
    } = useSearchWorkshops();

    // @ts-ignore
    let tableRef: HTMLTableElement | null = useRef(null);

    useEffect(() => {
        if (!tableRef || !tableRef.rows) {
            return;
        }
        const tableRows = [...tableRef.rows];
        selectRow(tableRows, selectedRowId, DATA_ATTRIBUTE);
    });

    const isTargetRadioButton = (target: { classList: { value: any } }) => {
        const classListValue = target.classList.value;
        return classListValue === 'radio-text' || classListValue === 'radio';
    };

    const handleActiveRowChange = (event: any) => {
        event.preventDefault();
        event.stopPropagation();

        if (isTargetRadioButton(event.target)) handleSelection(event, workshops);
    };

    const renderTableHead = (column: React.Key, sortByValue: any, sortDirValue: string) => {
        const tableHeadClassNames = classNames('user-select-none', 'sort-column');

        return (
            <th
                key={column}
                className={tableHeadClassNames}
                onClick={handleSortChange}
                data-field={column}
                data-sortby={sortByWorkshopColumns[column as string]}
            >
                <span>
                    <SortArrows direction={sortDirValue} />
                    <span>
                        <FormattedMessage id={`workshopAssignment.columnLabels.${column}`} />
                    </span>
                </span>
            </th>
        );
    };

    const rows = sortByProperty(workshops, sortBy as keyof ExternalWorkshop, sortDir);

    const tableClassNames = classNames(
        'table',
        'table-layout-fixed',
        'table-column-overflow-hidden',
        'table-sticky',
        'table-head-filled',
    );

    const renderTable = () =>
        rows.length ? (
            <div className="table-responsive margin-top-10">
                <table ref={(node) => (tableRef = node)} data-testid="workshop-table" className={tableClassNames}>
                    <colgroup>
                        <col className="table-checkbox" />
                        {workshopColumns.map((column: React.Key) => (
                            <col key={column} />
                        ))}
                    </colgroup>
                    <thead>
                        <tr data-testid="workshop-table-header">
                            <th className="table-checkbox" style={{ paddingRight: '0px', paddingLeft: '8px' }} />
                            {workshopColumns.map((column: React.Key) =>
                                renderTableHead(column as ColumnKey, sortBy, sortDir),
                            )}
                        </tr>
                    </thead>
                    <tbody data-testid={'workshop-table-body-dialog'}>
                        {rows.map((row: ExternalWorkshop) => (
                            <ReassignVehiclesDialogTableRow
                                key={`workshop-selection-${row.workshop_id}`}
                                row={row}
                                columns={workshopColumns}
                                onClick={handleActiveRowChange}
                                selectedRowId={selectedRowId}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
        ) : (
            <NotFoundState
                headline={<FormattedMessage id="workshopAssignment.notFoundState.headline" />}
                message={<FormattedMessage id="workshopAssignment.notFoundState.message" />}
            />
        );

    if (hasErrors) {
        return <InternalErrorState reloadTriggerFunction={triggerReload} />;
    }
    if (isLoading) {
        return <ContentLoadingScreen />;
    }

    return (
        <>
            <h5 className="margin-bottom-20">
                <FormattedMessage id="workshopAssignment.reassignVehiclesDialog.reassignTo.header" />
            </h5>
            <div className="display-flex justify-content-start flex-nowrap margin-bottom-10">
                <WorkshopSelectionStepSearch
                    workshopSearchText={searchWorkshopNameText}
                    citySearchText={searchCityText}
                    setSearchWorkshopNameText={setSearchWorkshopNameTextDebounced}
                    setSearchCityText={setSearchCityTextDebounced}
                />
            </div>
            {renderTable()}
        </>
    );
};

const ContentLoadingScreen = () => (
    <>
        <ContentLoader height={20} width={370} className="padding-left-15 margin-top-15" />
        <ContentLoader height={35} width={150} className="padding-left-15 margin-top-50" />
    </>
);

export default WorkshopSelectionStep;
