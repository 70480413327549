const getRowByDataAttribute = (rows: any = [], value = '', attribute = 'data-key') =>
    rows.find((row: any) => {
        const dataAttribute = row.attributes[attribute];
        if (dataAttribute) {
            return dataAttribute.value === value;
        }
        return false;
    });

const getCheckboxCell = (row: any) =>
    [...row.children].find((child) => child.className && child.className === 'table-checkbox');

export const selectRow = (rows: any[] | undefined, rowId: string | undefined, dataAttribute: string | undefined) => {
    const row = getRowByDataAttribute(rows, rowId, dataAttribute);
    if (row) {
        // Accessing the input node directly is slightly faster than using
        //    const [inputNode] = row.getElementsByTagName('input');
        //    inputNode.checked = true;
        // Means, when having many nodes like a thousand or more, accessing it directly
        // results in better performance,
        // row.firstChild.firstChild.td[].checked = true;
        const checkboxCell = getCheckboxCell(row);
        if (checkboxCell) {
            checkboxCell.firstChild.firstChild.checked = true;
        }
    }
};

export const deselectRow = (
    rows: HTMLTableRowElement[] | undefined,
    rowId: string | undefined,
    dataAttribute: string | undefined,
) => {
    const row = getRowByDataAttribute(rows, rowId, dataAttribute);
    if (row) {
        // Accessing the input node directly is slightly faster than using
        //    const [inputNode] = row.getElementsByTagName('input');
        //    inputNode.checked = false;
        // Means, when having many nodes like a thousand or more, acessing it directly
        // results in better performance,
        const checkboxCell = getCheckboxCell(row);
        if (checkboxCell) {
            checkboxCell.firstChild.firstChild.checked = false;
        }
    }
};

export const setHeaderCheckboxState = (rows: string | any[], selectedRowIds: string | any[]) => {
    const [headerRow] = rows;
    if (headerRow) {
        const checkboxCell = getCheckboxCell(headerRow);
        if (checkboxCell) {
            const checkbox = checkboxCell.firstChild.firstChild;

            const amountRows = rows.length - 1; // without the header row
            const amountSelectedRows = selectedRowIds.length;

            if (amountRows === amountSelectedRows) {
                checkbox.indeterminate = false;
                checkbox.checked = true;
            } else if (!amountSelectedRows) {
                checkbox.indeterminate = false;
                checkbox.checked = false;
            } else {
                checkbox.indeterminate = true;
            }
        }
    }
};
